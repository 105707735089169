.about {
  &__title {
    font-size: 46px;
    line-height: 48px;
    font-family: 'Playfair Display', serif;
    margin: 0 0 45px;
  }
  &__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    color: #797979;
  }
  &__top-text {
    margin-bottom: 75px;
  }
  &__items {
    margin-bottom: 140px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 75px;
    &:nth-child(2n) {
      .about__content:first-child {
        order: 1;
      }
      .about__content:nth-child(2n) {
        margin-right: 30px;
      }
    }
    &:nth-child(2n+1) {
      .about__content:first-child {
        margin-right: 30px;
      }
    }
  }
  &__img img {
    vertical-align: middle;
  }
  &__info {
    width: 100%;
  }
  .title {
    position: relative;
    margin-bottom: 65px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      left: 0;
      bottom: -20px;
      background-color: #e8e8e8;
    }
  }
}
@media screen and (max-width: 1279px) {
  .about__item {
    align-items: center;
  }
  .about__img {
    width: 50%;
    img {
      width: 100%;
      height: auto;
    }
  }
}
@media screen and (max-width: 1023px) {
  .about {
    text-align: center;
  }
  .about__item {
    flex-direction: column;
    margin-bottom: 50px;
  }
  .about__item:nth-child(n) {
    .about__content:nth-child(n) {
      margin-right: 0;
    }
    .about__content:first-child {
      order: 0;
    }
  }
  .about__img {
    margin-bottom: 20px;
  }
  .about .title {
    margin-bottom: 45px;
  }
}
@media screen and (max-width: 767px) {
  .about__img {
    width: 100%;
  }
  .about__items {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 479px) {
  .about__items {
    margin-bottom: 0;
  }
}
