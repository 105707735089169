.jcf-select.jcf-unselectable {
  height: 100%;
  margin: 0;
  border: none;
  border-left: 1px solid #8b8b8b;
  border-right: 1px solid #8b8b8b;
  min-width: 380px;
  cursor: pointer;
  padding: 0 30px;
}
.jcf-drop-active.jcf-select .jcf-select-opener::after {
  border-width: 0 4px 6px;
  border-color: transparent transparent #8b8b8b;
}
.jcf-select span.jcf-select-text {
  margin: 0;
  font-family: 'MullerBold';
  font-size: 16px;
  text-transform: uppercase;
  color: #8b8b8b;
  line-height: 72px;
  cursor: pointer;
}
.jcf-select .jcf-select-opener {
  background-color: transparent;
}
.jcf-select-drop .jcf-select-drop-content {
  border: 1px solid #ccc;
}
.jcf-list .jcf-option {
  cursor: pointer;
}
.jcf-select .jcf-select-opener {
  &::after {
    position: absolute;
    content: '';
    right: 15px;
    top: 50%;
    margin-top: -3px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0;
    border-color: #8b8b8b transparent transparent;
  }
}
.jcf-focus,
.jcf-focus * {
  border-color: #8b8b8b !important;
}
.jcf-option {
  font-family: 'MullerRegular';
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
}
