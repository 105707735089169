.product-info {
  display: flex;
  padding-bottom: 40px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 30px;
  .title {
    text-transform: none;
    font-size: 36px;
    line-height: 38px;
  }
  &__item {
    .catalog__value {
      width: 70%;
    }
    &:first-child {
      width: 705px;
    }
    &:nth-child(2) {
      width: calc(100% - 705px);
      .catalog__property {
        line-height: 30px;
      }
    }
  }
  & .subtitle {
    font-size: 26px;
    line-height: 38px;
    text-transform: none;
    margin-bottom: 35px;
  }
  .catalog__description {
    border-bottom: none;
  }
}
@media screen and (max-width: 1023px) {
  .product-info {
    flex-direction: column;
  }
  .product-info__description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .catalog__description {
      width: 50%;
      align-items: center;
    }
  }
  .product-info__item .catalog__value {
    width: 220px;
    padding-right: 25px;
    line-height: 15px;
    text-align: right;
  }
  .product-info__item:first-child,
  .product-info__item:nth-child(2) {
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .product-info {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .product-info__item:first-child {
    width: auto;
  }
  .product-info__description {
    flex-direction: column;
  }
  .product-info__description .catalog__description {
    width: 100%;
    text-align: center;
  }
  .accommodation .catalog__description {
    display: inline-block;
  }
  .product-info__item .catalog__value {
    padding-right: 0;
  }
  .product-info__description .catalog__description {
    border-bottom: 1px solid #ccc;
  }
  .accommodation .catalog__description {
    border: none;
  }
}
@media screen and (max-width: 479px) {
  .product-info {
    border-bottom: none;
  }
  .product-info__item .catalog__value {
    width: 150px;
  }
}
