.top-section {
  position: relative;
  height: 560px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 55px;
  @media screen and (max-width: 1366px) {
    height: 320px;
  }
  @media screen and (max-width: 1023px) {
    background: none;
    height: auto;
    margin-bottom: 0;
  }
}
