.about-section {
  padding: 170px 0 70px;
  background: url('../images/bg-about.jpg') no-repeat;
      background-size: cover;
  &__wrap {
    max-width: 1210px;
    margin: 0 auto;
  }
  &__box {
    max-width: 560px;
    width: 100%;
    padding: 30px 40px 45px 55px;
    background-color: #fff;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1;
    &:after {
      position: absolute;
      content: '';
      @extend %icon-about-decor;
      right: 50px;
      bottom: 0;
      z-index: -1;
    }
  }
  &__title {
    display: block;
    font-family: 'Playfair Display', serif;
    display: block;
    text-transform: uppercase;
    font-size: 26px;
    margin-bottom: 40px;
  }
  &__text {
    color: #333;
    line-height: 22px;
    height: 200px;
    p {
      margin: 0 0 20px;
    }
  }
}
@media screen and (max-width: 1279px) {
  .about-section__wrap {
    max-width: 900px;
  }
}
@media screen and (max-width: 767px) {
  .about-section__box {
    max-width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .about-section {
    padding: 70px 0;
  }
}
