.search-section {
  margin-top: -125px;
  position: relative;
}
.search-tabs {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 107px;
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    text-transform: uppercase;
    li {
      float: left;
      width: 134px;
      line-height: 72px;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      a {
        text-decoration: none;
        display: block;
        font-weight: bold;
        background-color: #e9e9e9;
        color: #8b8b8b;
      }
      &.active {
        a {
          background-color: $main;
          color: #fff;
        }
      }
    }
  }
}
.tab-content {
  max-width: 850px;
  width: 100%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  &__item {
    display: flex;
  }
}
.search__more {
  position: relative;
  font-family: 'MullerBold';
  color: $main;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 72px;
  padding: 0 30px;
  flex: 1 0 auto;
  cursor: pointer;
  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    background-color: $main;
  }
  &::after {
    width: 2px;
    top: 50%;
    transform: translateY(-50%);
    height: 10px;
    right: 24px;
  }
  &::before {
    height: 2px;
    width: 10px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  &.active::after {
    display: none;
  }
}
.search-form {
  flex: 1 0 auto;
  display: flex;
  &__select {
    flex: 1 0 auto;
  }
  &__button {
    width: 170px;
    border: none;
    background-color: $main;
    line-height: 68px;
    color: #fff;
    font-family: 'MullerRegular';
    letter-spacing: 2px;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 0;
    cursor: pointer;
    border: 1px solid $main;
    transition: background-color .3s, color .3s;
    flex: 1 0 auto;
    padding: 0;
    &.non-active {
      display: none;
    }
    > svg {
      width: 17px;
      height: 23px;
      fill: #fff;
      margin-right: 10px;
      margin-bottom: 4px;
      transition: fill .3s;
    }
    > svg,
    > span {
      display: inline-block;
      vertical-align: middle;
      font-weight: bold;
    }
    @media screen and (min-width: 1250px) {
      &:hover {
        background-color: #fff;
        color: $main;
        transition: background-color .3s, color .3s;
      }
      &:hover svg {
        fill: $main;
        transition: fill .3s;
      }
    }
  }
  &__hide {
    width: 170px;
    display: none;
    position: relative;
    line-height: 70px;
    color: $main;
    font-family: 'MullerRegular';
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    &.active {
      display: block;
    }
    &::after {
      position: absolute;
      content: '';
      right: 15px;
      top: 50%;
      margin-top: -6px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 3px 6px;
      border-color: transparent transparent #8b8b8b;
    }
  }
}
.search-details {
  display: none;
  height: 180px;
  position: absolute;
  width: 100%;
  background-color: #eee;
  top: calc(100% + 20px);
  left: 0;
  font-size: 0;
  padding: 20px 30px;
  &__title {
    font-family: 'MullerBold';
    text-transform: uppercase;
    color: #000;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  > form {
    @extend %clearfix;
  }
  &__items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__item {
    > label {
      font-family: 'MullerBold';
      text-transform: uppercase;
      color: $main;
      display: block;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 10px;
    }
    .jcf-select.jcf-unselectable {
      width: auto;
      min-width: auto;
      height: auto;
      border: 1px solid #e8e8e8;
      padding: 0 40px 0 30px;
    }
    .jcf-select span.jcf-select-text {
      line-height: 35px;
      font-family: 'mullerRegular';
      font-size: 12px;
      color: #8b8b8b;
      letter-spacing: 1px;
    }
  }
  &__button {
    display: block;
    float: right;
    text-align: center;
    padding: 0;
    line-height: 33px;
    width: 150px;
    padding: 5px 0 1px;
  }
}
@media screen and (max-width: 1279px) {
  .search-section {
    margin-top: -382px;
  }
  .search-tabs  {
    flex-flow: column wrap;
    align-items: center;
    > ul {
      margin-bottom: 20px;
    }
  }
  .tab-content {
    max-width: 100%;
  }
  .search-details {
    height: 200px;
  }
  .search-details__item .jcf-select.jcf-unselectable {
    padding: 0 30px 0 20px;
  }
  .search-details__items {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1023px) {
  .search-form .jcf-select.jcf-unselectable {
    height: 70px;
  }
  .search-details__item .jcf-select.jcf-unselectable {
    width: auto;
  }
  .search-form .jcf-select .jcf-select-drop {
    left: 0;
    right: 0;
  }
  .search-details .jcf-select .jcf-select-drop {
    left: -1px;
    right: -1px;
  }
  .search-form__button {
    width: 100%;
    line-height: 50px;
  }
  .search-details__button {
    width: 150px;
    line-height: 40px;
  }
  .search-section {
    margin-top: 30px;
  }
  .search-tabs {
    margin-top: -380px;
    margin-bottom: 150px;
  }
  .search-tabs > ul li {
    line-height: 40px;
  }
  .tab-content__item,
  .search-form {
    flex-flow: column wrap;
  }
  .tab-content {
    background-color: transparent;
    box-shadow: none;
  }
  .tab-content__item {
    width: 300px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }
  .jcf-select.jcf-unselectable.jcf-select-search-form__select {
    min-width: 100%;
    border: none;
  }
  .search__more {
    width: 100%;
    order: 1;
  }
  .search-form {
    width: 100%;
  }
  .search-details {
    width: 100%;
    height: auto;
    text-align: center;
    padding: 20px;
    top: 100%;
  }
  .search-details__title {
    display: none;
  }
  .search-details__items {
    flex-flow: column wrap;
  }
  .search-details__item {
    margin-bottom: 10px;
    > label {
      margin-bottom: 0;
    }
  }
  .search-details__button {
    float: none;
    margin: 0 auto;
  }
  .search-details__title {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .search-tabs {
    width: 100%;
  }
  .search-details__item .jcf-select.jcf-unselectable {
    padding: 0 20px 0 15px;
  }
}
@media screen and (max-width: 479px) {
  .search-details {
    padding: 20px;
  }
  .search-details__title {
    margin-bottom: 10px;
  }
  .search-tabs {
    margin-bottom: 120px;
  }
}
