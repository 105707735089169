.pagination {
  list-style-type: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  &__holder {
    text-align: center;
  }
  li {
    cursor: pointer;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    transition: background-color .3s;
    a {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #bdbdbd;
      transition: color .3s;
    }
    &.prev,
    &.next {
      width: 83px;
      svg {
        fill: #000;
        width: 83px;
        height: 8px;
        transition: fill .3s;
      }
    }
    &.prev {
      margin-right: 20px;
    }
    &.next {
      margin-left: 20px;
    }
    &.active {
      background-color: $main;
      a {
        color: #fff;
      }
    }
    @media screen and (min-width: 1250px) {
      &:hover {
        background-color: $main;
        transition: background-color .3s;
        a {
          color: #fff;
          transition: color .3s;
        }
      }
      &.prev:hover,
      &.next:hover {
        background-color: transparent;
      }
      &:hover svg {
        fill: $main;
        transition: fill .3s;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .pagination li {
    &.hide {
      display: none;
    }
    &.prev {
      margin-right: 10px;
    }
    &.next {
      margin-left: 10px;
    }
    &.prev,
    &.next {
      width: 70px;
      svg {
        width: 70px;
        height: 6px;
      }
    }
  }
}
