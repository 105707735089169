.catalog {
  margin-left: 327px;
  margin-bottom: 55px;
  margin-right: -70px;
  min-height: 550px;
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -18px;
    align-items: flex-start;
  }
  &__item {
    position: relative;
    width: 281px;
    box-shadow: 0 2px 29px rgba(#070b22, .07);
    overflow: hidden;
    margin-bottom: 48px;
    margin-left: 18px;
  }
  &__img {
    display: block;
    > img {
      vertical-align: middle;
    }
  }
  &__info {
    position: relative;
    padding: 25px 30px 0 25px;
    background-color: #fff;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 48px;
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed $main;
    }
    .sales-content__slider & {
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 100px;
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed $main;
      }
    }
  }
  &__title {
    display: block;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    color: #221238;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1px;
    margin-bottom: 30px;
    transition: color .3s;
    @media screen and (min-width: 1250px) {
      &:hover {
        color: $main;
        transition: color .3s;
      }
    }
  }
  &__description,
  &__price,
  &__full-price {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
  }
  &__description {
    border-bottom: 1px dotted #f7f7f8;
    &:last-child {
      border: none;
      margin-bottom: 20px;
    }
  }
  &__property {
    color: #bdbdbd;
  }
  &__value {
    color: #221238;
  }
  &__price {
    font-weight: 700;
    line-height: 43px;
    > .catalog__property,
    > .catalog__value {
      color: $main;
    }
    @media screen and (min-width: 1250px) {
      &:hover .catalog__explore {
        transform: translateY(0);
        transition: transform .3s;
      }
    }
  }
  &__full-price {
    font-weight: 700;
    line-height: 43px;
    margin-bottom: 10px;
    > .catalog__property{
      color: #000;
    }
    > .catalog__value {
      color: $main;
    }
  }
  &__explore {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $main;
    color: #fff;
    width: 100%;
    height: 43px;
    text-align: center;
    transform: translateY(250%);
    transition: transform .3s;
  }
}

@media screen and (max-width: 1439px) {
.catalog {
  margin-right: 0;
  margin-left: 261px;
}
}

@media screen and (max-width: 1279px) {
  .catalog__item {
    width: 350px;
  }
  .catalog__img img {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 1023px) {
  .catalog {
    margin-left: 0;
  }
  .catalog__item {
    width: 330px;
  }
}
@media screen and (max-width: 767px) {
  .catalog__item {
    width: 100%;
  }
}
