.charter-season {
  background-color: #ebf1f6;
  padding: 20px 35px;
  margin-right: -50px;
  &__item {
    border-bottom: 1px solid #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase;
    &:last-child {
      border-bottom: none;
    }
  }
  &__name,
  &__price {
    color: #838383;
    font-weight: 700;
    span + span {
      font-weight: 400;
      font-size: 12px;
      color: #838383;
    }
  }
  &__price > span {
    color: #000;
  }
}
.charter-text {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 40px;
  padding-bottom: 30px;
  .title {
    text-transform: none;
  }
}
.charter-info {
  &__item {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #797979;
    font-size: 15px;
    line-height: 22px;
  }
}
@media screen and (max-width: 1279px) {
  .charter-season {
    padding: 10px 30px;
  }
  .charter-season__item {
    line-height: 24px;
    text-align: center;
  }
}
@media screen and (max-width: 1023px) {
  .charter-season {
    margin-right: 0;
  }
  .charter-info__item {
    text-align: center;
    margin-bottom: 35px;
  }
  .product-info .charter-info__item .subtitle {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 479px)  {
  .charter-text {
    border-bottom: none;
  }
}
