.crumbs {
  padding: 30px 0;
  background-color: #f2f2f2;
  margin-bottom: 40px;
  @include response(767px) {
    padding: 15px 0;
    margin-bottom: 20px;
  }
  &.category-crambs {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(#fff, .9);
    @media screen and (max-width: 1023px) {
      position: static;
      background-color: #f2f2f2;
    }
  }
  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  &__item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 24px;
    text-transform: uppercase;
    font-family: 'Open sans', sans-serif;
    font-weight: 300;
    color: $grey;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    > a {
      text-decoration: none;
      color: inherit;
      transition: color .3s;
      @media screen and (min-width: 1250px) {
        &:hover {
          color: $main;
          transition: color .3s;
        }
      }
    }
    &:last-child {
      color: #5c5c5c;
      margin-right: 0;
      &::after {
        width: 33%;
        height: 1px;
        right: 67%;
        top: calc(100% + 12px);
      }
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: #5c5c5c;
      top: 0;
      right: -13px;
    }
  }
}
@media screen and (max-width: 1023px) {
  .crumbs {
    text-align: center;
    &__item:last-child::after {
      display: none;
    }
  }
}
