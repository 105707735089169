.building-process-item {
  .text.text-img {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include response(767px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    img {
      flex: 0 0 auto;
      margin: 0 35px 0 0;
      @include response(767px) {
        margin: 0 0 10px;
      }
    }
    p {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
    }
  }
}
