.contact {
  .about__top-text {
    max-width: 655px;
  }
  & .title {
    margin-bottom: 40px;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    .title {
      margin-bottom: 20px;
      font-size: 21px;
    }
  }
  &__img {
    margin-right: 35px;
  }
  &__contacts {
    margin-bottom: 40px;
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: -20px;
      background-color: #e8e8e8;
    }
    a {
      display: block;
    }
  }
  &__mail,
  &__phone {
    font-family: 'Open Sans';
    font-size: 13px;
    line-height: 17px;
    color: #262626;
    &::before {
      content: '';
      display: inline-block;
      margin-right: 12px;
    }
  }
  &__mail::before {
    content: '@';
  }
  &__phone::before {
    content: 'T';
  }
  &__time {
    span {
      display: block;
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #262626;
      text-transform: uppercase;
    }
    span + span {
      font-size: 13px;
      color: #797979;
      line-height: 30px;
    }
  }
}
@media screen and (max-width: 1279px) {
  .contact__img {
    width: 250px;
    img {
      width: 100%;
      height: auto;
    }
  }
}
@media screen and (max-width: 1023px) {
  .contact {
    text-align: center;
    .about__top-text {
      max-width: 100%;
    }
  }
  .contact__items {
    flex-direction: column;
    align-items: center;
  }
  .contact__item {
    width: 500px;
  }
}
@media screen and (max-width: 767px) {
  .contact__img {
    display: none;
  }
  .contact__item,
  .contact__info {
    width: 100%;
  }
  .contact__item {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #e8e8e8;
    &:last-child {
      border: none;
    }
  }
  .contact__contacts{
    margin-bottom: 20px;
    &::after {
      display: none;
    }
  }
}
