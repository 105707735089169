.crew-popup {
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 20px 170px;
  box-shadow: 0 8px 42px rgba(#000, .18);
  background-color: #fff;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  @include response(1279px) {
    height: 100vh;
    overflow-y: auto;
  }
  @include response(1023px) {
    padding: 70px 100px 10px;
  }
  @include response(767px) {
    padding: 100px 100px 50px;
  }
  @include response(479px) {
    padding: 100px 50px 50px;
  }
  &__close {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    background-color: $main;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    svg {
      width: 21px;
      height: 21px;
      fill: #fff;
    }
    @include response(1023px) {
      top: 60px;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__item {
    width: 252px;
    margin-bottom: 10px;
    @include response(1023px) {
      width: 45%;
    }
    @include response(767px) {
      width: 100%;
    }
    input,
    .jcf-select.jcf-unselectable {
      line-height: 24px;
      height: 44px;
      padding: 10px;
      border: 1px solid #e8e8e8;
      min-width: 100%;
    }
    .jcf-select span.jcf-select-text {
      font-family: 'Open Sans', sans-serif;
      line-height: 24px;
      font-weight: 300;
      font-size: 16px;
      color: #8b8b8b;
      text-transform: none;
    }
    label {
      display: block;
      margin-bottom: 15px;
      cursor: pointer;
      font-size: 13px;
      line-height: 15px;
      color: #262626;
      text-transform: uppercase;
      span {
        color: #d45252;
      }
    }
    .crew-popup__checkbox {
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__textarea {
    width: 800px;
    textarea {
      resize: none;
      line-height: 20px;
      padding: 10px 20px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 14px;
      color: #8b8b8b;
      width: 100%;
      border: 1px solid #e8e8e8;
      height: 125px;
    }
    @include response(1023px) {
      width: 100%;
    }
  }
  &__checkbox {
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    text-transform: none;
    display: inline-block;
    vertical-align: middle;
    label {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
      margin-left: 12px;
      max-width: 150px;
    }
  }
  &__note {
    font-weight: 300;
    color: #bdbdbd;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 10px;
    span {
      color: #d45252;
    }
  }
  &__specify {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 200px;
    margin-bottom: 10px;
    .crew-popup__checkbox {
      width: 250px;
      margin-bottom: 20px;
      @include response(1023px) {
        width: 50%;
      }
      @include response(767px) {
        width: 100%;
      }
    }
    label {
      text-transform: uppercase;
    }
    @include response(1023px) {
      max-height: 300px;
    }
    @include response(767px) {
      max-height: 100%;
      align-items: center;
    }
  }
  .button {
    width: 230px;
    @include response(1023px) {
      display: block;
      margin: 0 auto;
      width: 220px;
    }
  }
}
input[type="checkbox"] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  min-width: 1px;
  width: 24px;
  height: 24px;
  visibility: hidden;
  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    visibility: visible;
    cursor: pointer;
  }
  &::before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid #e8e8e8;
  }
  &::after {
    @extend %icon-add;
    top: 8px;
    left: 7px;
    opacity: 0;
  }
  &:checked::after {
    opacity: 1;
  }
}
