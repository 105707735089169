.content {
  position: relative;
  &::after {
    @extend %icon-decor;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  &__slider {
    margin-bottom: 130px;
    &.build-slider {
      .catalog__price > .catalog__property {
        color: #000;
        font-size: 16px;
      }
      .catalog__price > .catalog__value {
        font-size: 16px;
      }
    }
    .catalog__title {
      text-transform: uppercase;
      font-size: 18px;
    }
  }
  &__slider .slick-dots {
    position: static;
    display: block;
    text-align: center;
    transform: translateY(0);
    margin-top: 60px;
    li {
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
      margin-right: 45px;
      border-color: #cdcdcd;
      &::after {
        border-bottom: 2px solid rgba(#cdcdcd, .3);
        top: 50%;
        height: 0;
        width: 45px;
        transform: translate(-115%, 0);
      }
      &.slick-active{
        background-color: $main;
        &::before {
          border-color: $main;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__slide {
    overflow: hidden;
    margin: 13px;
    box-shadow: 0 0 26px rgba(#070b22, .05);
    .catalog__price {
      font-size: 16px;
    }
  }
}
.main-title,
.title,
.subtitle {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  letter-spacing: 1px;
}
.main-title {
  font-size: 46px;
  line-height: 48px;
  display: block;
  margin: 0 0 35px;
}
.text {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #797979;
  margin-bottom: 95px;
}
.title {
  font-size: 26px;
  line-height: 28px;
  text-transform: uppercase;
  margin-bottom: 35px;
}
.subtitle {
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
@media screen and (max-width: 1023px) {
  .content {
    text-align: center;
  }
  .subtitle,
  .title {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .content__slider {
    margin-bottom: 60px;
  }
  .content__slider .slick-dots {
    margin-bottom: 0;
  }
  .content__slider .slick-dots li {
    margin-right: 20px;
    &::after {
      width: 25px;
    }
  }
}
