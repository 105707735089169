.destination-details {
  margin-bottom: 130px;
  & .title {
    text-transform: none;
    font-size: 46px;
    line-height: 48px;
    margin-bottom: 40px;
  }
  &__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #797979;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 85px;
  }
  &__button {
    display: inline-block;
    padding: 0 30px;
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 1023px) {
  .destination-details {
    text-align: center;
  }
  .destination-details__text {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  .destination-details .title {
    font-size: 36px;
    line-height: 38px;
    margin-bottom: 20px;
  }
  .destination-details__text {
    margin-bottom: 20px;
  }
  .destination-details__button {
    display: block;
    text-align: center;
    padding: 0;
    font-size: 10px;
    line-height: 15px;
    padding: 15px 0;
  }
  .destination-details {
    margin-bottom: 90px;
  }
}
@media screen and (max-width: 479px) {
  .destination-details__button {
    margin-bottom: 50px;
  }
  .destination-details {
    margin-bottom: 50px;
  }
}
