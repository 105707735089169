
%icon-about-decor {
  background-image: url(../images/sprite.png);
  background-position: 0px 0px;
  width: 278px;
  height: 326px;
}
%icon-add {
  background-image: url(../images/sprite.png);
  background-position: -646px -268px;
  width: 11px;
  height: 8px;
}
%icon-crew-decor {
  background-image: url(../images/sprite.png);
  background-position: -283px 0px;
  width: 416px;
  height: 168px;
}
%icon-decor {
  background-image: url(../images/sprite.png);
  background-position: 0px -331px;
  width: 251px;
  height: 334px;
}
%icon-lang1 {
  background-image: url(../images/sprite.png);
  background-position: -646px -173px;
  width: 19px;
  height: 19px;
}
%icon-lang2 {
  background-image: url(../images/sprite.png);
  background-position: -669px -221px;
  width: 18px;
  height: 19px;
}
%icon-lang3 {
  background-image: url(../images/sprite.png);
  background-position: -670px -173px;
  width: 19px;
  height: 19px;
}
%icon-lang4 {
  background-image: url(../images/sprite.png);
  background-position: -646px -221px;
  width: 18px;
  height: 19px;
}
%icon-lang5 {
  background-image: url(../images/sprite.png);
  background-position: -646px -197px;
  width: 19px;
  height: 19px;
}
%icon-lang6 {
  background-image: url(../images/sprite.png);
  background-position: -670px -197px;
  width: 19px;
  height: 19px;
}
%icon-logo-lion {
  background-image: url(../images/sprite.png);
  background-position: -457px -318px;
  width: 67px;
  height: 90px;
}
%icon-logo {
  background-image: url(../images/sprite.png);
  background-position: -283px -318px;
  width: 169px;
  height: 171px;
}
%icon-lumar {
  background-image: url(../images/sprite.png);
  background-position: -283px -173px;
  width: 358px;
  height: 140px;
}
%icon-mail {
  background-image: url(../images/sprite.png);
  background-position: -669px -245px;
  width: 16px;
  height: 15px;
}
%icon-phone {
  background-image: url(../images/sprite.png);
  background-position: -646px -245px;
  width: 18px;
  height: 18px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  %icon-about-decor {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-add {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-crew-decor {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-decor {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-lang1 {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-lang2 {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-lang3 {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-lang4 {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-lang5 {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-lang6 {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-logo-lion {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-logo {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-lumar {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-mail {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
  %icon-phone {
    background-image: url(../images/sprite@2x.png);
    background-size: 699px 665px;
  }
}
