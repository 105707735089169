.content-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 55px;
  &__item {
    width: 553px;
    margin-bottom: 80px;
  }
  &__title {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    color: #262626;
    font-size: 20px;
    // line-height: 50px;
    padding: 13px 0;
    text-transform: uppercase;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 60px;
  }
  &__img {
    margin-bottom: 70px;
  }
  &__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #797979;
    margin-bottom: 40px;
  }
  &__button {
    display: inline-block;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    padding: 0 70px;
    border: 1px solid #282828;
    line-height: 44px;
    font-size: 16px;
    text-transform: uppercase;
    color: #282828;
    transition: border-color .3s, color .3s;
    @media screen and (min-width: 1250px) {
      &:hover {
        border-color: $main;
        color: $main;
        transition: border-color .3s, color .3s;
      }
    }
  }
}
@media screen and (max-width: 1279px) {
  .content-info__item {
    width: 480px;
    img {
      width: 100%;
      height: auto;
    }
  }
}
@media screen and (max-width: 1023px) {
  .content-info__item {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .content-info__button {
    display: block;
    text-align: center;
    padding: 0;
    font-size: 14px;
    line-height: 38px;
  }
  .content-info__img,
  .content-info__title {
    margin-bottom: 30px;
  }
  .content-info {
    margin-bottom: 0;
  }
}
