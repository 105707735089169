.registration {
  margin-bottom: 100px;
  @include response(1279px) {
    margin-bottom: 40px;
  }
  .title-iteration2 {
    margin-bottom: 40px;
    @include response(1279px) {
      margin-bottom: 20px;
    }
  }
  .text {
    margin-bottom: 45px;
    @include response(1024px) {
      margin-bottom: 25px;
    }
  }
}
.text.text-registration {
  color: #262626;
  max-width: 690px;
  margin-bottom: 30px;
  @include response(1279px) {
    margin-bottom: 20px;
  }
}
.registration-btn {
  line-height: normal;
  padding: 11px 10px;
  min-width: 406px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  @include response(767px) {
    min-width: inherit;
  }
}
.sub-title-registr {
  font-weight: 700;
  font-size: 15px;
  color: #333;
  margin-bottom: 23px;
  @include response(1279px) {
    margin-bottom: 5px;
  }
}
