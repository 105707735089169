.product-preview {
  margin-bottom: 110px;
  text-align: center;
  &__title {
    text-align: left;
    margin-left: 155px;
    margin-bottom: 50px;
    position: relative;
    font-family: 'Playfair Display', serif;
    font-size: 36px;
    line-height: 38px;
    color: #797979;
    text-transform: uppercase;
    &::before {
      content: 'Yacht layout';
      display: block;
      position: absolute;
      left: 0;
      top: -13px;
      text-transform: uppercase;
      font-family: 'Open Sans', sans-serif;
      font-size: 13px;
      line-height: 15px;
      color: #797979;
    }
  }
  &__tabs {
    margin-bottom: 65px;
  }
  &__img {
    position: relative;
    display: inline-block;
    .fullsize {
      top: 50%;
      left: 50%;
      margin: -23px 0 0 -23px;
    }
  }
  &__tabs-content {
    margin-bottom: 55px;
  }
  &__tabs-nav {
    list-style-type: none;
    padding-left: 15px;
    margin: 0;
    font-size: 0;
  }
  &__tab {
    position: relative;
    display: inline-block;
    margin-right: 40px;
    a {
      display: inline-block;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      width: 23px;
      left: 0;
      bottom: 0;
      background-color: #cdcdcd;
      transition: width .3s;
    }
    &.active {
      .product-preview__tab-img {
        opacity: 1;
        transition: opacity .3s;
      }
      &::after {
        width: 100%;
        background-color: $main;
        transition: width .3s;
      }
    }
    @media screen and (min-width: 1250px) {
      &:hover .product-preview__tab-img {
        opacity: 1;
        transition: opacity .3s;
      }
      &:hover::after {
        width: 100%;
        background-color: $main;
        transition: width .3s;
      }
    }
  }
  &__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    line-height: 30px;
    letter-spacing: 1px;
    color: #797979;
    text-transform: uppercase;
    text-align: left;
  }
  &__tab-img {
    margin-bottom: 30px;
    opacity: .5;
    transition: opacity .3s;
  }
  &__button {
    display: inline-block;
    line-height: 44px;
    padding: 0 39px;
  }
}
@media screen and (max-width: 1023px) {
  .product-preview__img img {
    width: 100%;
    height: auto;
  }
  .product-preview__tab {
    margin-right: 30px;
  }
  .product-preview__tabs-nav {
    padding: 0;
  }
  .product-preview__title {
    text-align: center;
    margin-left: 0;
    &::before {
      left: 50%;
      margin-left: -46px;
    }
  }
}
@media screen and (max-width: 767px) {
  .product-preview__tab {
    margin: 0 10px 20px;
  }
  .product-preview__tabs {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 479px) {
  .product-preview__tab.active .product-preview__tab-img {
    margin-bottom: 0;
  }
  .product-preview__tab {
    margin-bottom: 20px;
  }
  .product-preview__button {
    font-size: 14px;
    display: block;
    text-align: center;
    padding: 0;
  }
  .product-preview {
    margin-bottom: 50px;
  }
}
