.charter-with-us {
  margin-bottom: 130px;
  @include response(767px) {
    margin-bottom: 60px;
  }
  .title-iteration2 {
    padding: 20px 0 0;
  }
  @extend %clearfix;
  .text.black-text {
    color: #262626;
    margin-bottom: 30px;
  }
  .text {
    margin-bottom: 50px;
  }
  .info-holder {
    width: 680px;
    float: left;
    @include response(1023px) {
      float: none;
      width: 100%;
      margin: 0 0 20px;
    }
  }
  .button {
    min-width: 406px;
    padding: 13px 10px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    line-height: inherit;
    @include response(767px) {
      min-width: 100%;
    }
  }
  .info-holder.info-holder-procedure {
    float: none;
    width: 100%;
  }
}
.yacht-charter-list-holder {
  float: right;
  width: 305px;
  @include response(1023px) {
    float: none;
    width: 100%;
  }
  > .title {
    font-size: 21px;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
    padding: 30px 10px 30px 40px;
    background: #05a0a4;
    margin: 0;
    @include response(1023px) {
      padding: 15px;
    }
  }
  .yacht-charter-list {
    background: #ebf1f6;
    list-style: none;
    padding: 18px 0;
    margin: 0;
    li {
      margin: 0 0 16px;
      &:last-child {
        margin: 0;
      }
    }
     a {
      font-size: 13px;
      color: #797979;
      text-transform: uppercase;
      display: block;
      position: relative;
      transition: .3s;
      padding: 0 0 0 40px;
      @media screen and (min-width: 1279px) {
        &:hover {
          color: #262626;
          &::before {
            border-left: 7px solid #333;

          }
        }
      }
       &::before {
        display: block;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-left: 7px solid #cdcdcd;
        border-bottom: 3px solid transparent;
        top: 50%;
        left: 18px;
        transition: .3s;
        margin: -3px 0 0;
      }
    }
  }
}
