.destination {
  margin-bottom: 140px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #797979;
  .title {
    text-transform: none;
    color: #000;
    font-size: 32px;
  }
  &__text {
    max-width: 675px;
    margin-bottom: 50px;
  }
  &__item {
    display: flex;
    margin-bottom: 50px;
    transition: box-shadow .3s;
    align-items: center;
    @media screen and (min-width: 1250px) {
      &:hover {
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
        transition: box-shadow .3s;
      }
    }
  }
  &__info {
    flex: 0 1 auto;
  }
  &__img {
    margin-right: 24px;
    width: 283px;
    flex: 0 0 283px;
    img {
      vertical-align: middle;
    }
  }
  &__title {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    font-size: 21px;
    line-height: 23px;
    color: #000;
    margin-bottom: 20px;
    width: 320px;
  }
  &__subtitle {
    font-size: 10px;
    line-height: 12px;
    color: $main;
    text-transform: uppercase;
  }
  &__pagi {
    .prev {
      margin-right: 20px;
    }
    .next {
      margin-left: 20px;
    }
    svg {
      width: 83px;
      height: 9px;
      fill: #cecece;
      transition: fill .3s;
      display: inline-block;
      vertical-align: middle;
    }
    @media screen and (min-width: 1250px) {
      & a:hover svg {
        fill: $main;
        transition: fill .3s;
      }
    }
  }
  &__pagi-item {
    color: #cecece;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 36px;
    letter-spacing: 2px;
    line-height: 38px;
    display: inline-block;
    vertical-align: middle;
  }
}
@media screen and (max-width: 767px) {
  .destination__item {
    flex-direction: column;
    text-align: center;
  }
  .destination__title {
    width: 100%;
  }
  .destination__img {
    margin: 0 0 20px;
    flex: 0 0 auto;
  }
  .destination__pagi {
    text-align: center;
  }
}
