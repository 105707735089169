.not-found {
  position: relative;
  width: 100%;
  height: 750px;
  background-image: url('../images/404.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  &__top {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/404-top.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }
  &__button {
    display: block;
    position: absolute;
    z-index: 10;
    bottom: 112px;
    left: 50%;
    margin-left: -372px;
    width: 317px;
    line-height: 46px;
    text-align: center;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 20px;
    text-transform: uppercase;
    background-color: $main;
    transition: background-color .3s;
    @media screen and (min-width: 1250px) {
      &:hover {
        background-color: #048487;
        transition: background-color .3s;
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .not-found {
    height: 515px;
    background-image: url('../images/404-1300.jpg');
  }
  .not-found__button {
    bottom: 65px;
    margin-left: -310px;
  }
}
@media screen and (max-width: 870px) {
  .not-found {
    height: 345px;
    background-image: url('../images/404-870.jpg');
  }
  .not-found__button {
    width: 200px;
    line-height: 40px;
    bottom: 30px;
    margin-left: -200px;
  }
}
@media screen and (max-width: 580px) {
  .not-found {
    height: 230px;
    background-image: url('../images/404-580.jpg');
  }
  .not-found__button {
    width: 165px;
    line-height: 32px;
    bottom: 17px;
    margin-left: -145px;
    font-size: 14px;
  }
}
@media screen and (max-width: 430px) {
  .not-found {
    height: 171px;
    background-image: url('../images/404-430.jpg');
  }
  .not-found__button {
    width: 145px;
    line-height: 28px;
    bottom: 0;
    margin-bottom: 10px;
    margin-left: -73px;
  }
}
