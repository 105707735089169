.contact-us {
  background: url('../images/form-bg.jpg');
  background-size: cover;
  padding: 90px 0 60px;
  margin-bottom: 90px;
  &__title {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    font-size: 46px;
    line-height: 48px;
    color: #262626;
    margin-bottom: 30px;
    text-align: center;
  }
  &__form {
    width: 530px;
    margin: 0 auto;
    @extend %clearfix;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__item {
    margin-bottom: 20px;
    &--textarea {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
    &--name {
      width: 160px;
    }
    &--address {
      width: 250px;
    }
  }
  .jcf-select span.jcf-select-text {
    font-family: 'Open Sans', sans-serif;
    line-height: 44px;
    font-weight: 300;
    text-transform: none;
    text-align: center;
  }
  .jcf-select.jcf-unselectable {
    padding: 0 20px;
    border: 1px solid #e8e8e8;
    min-width: 158px;
    height: auto;
  }
  label {
    display: block;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 15px;
    > span {
      color: #d45252;
    }
  }
  input,
  textarea {
    width: 100%;
    border: 1px solid #e8e8e8;
    &:focus {
      border-color: $main;
    }
  }
  input {
    line-height: 24px;
    height: 44px;
    padding: 10px;
  }
  textarea {
    min-height: 150px;
    resize: none;
    line-height: 20px;
    padding: 10px 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #8b8b8b;
  }
  &__button {
    float: right;
    padding: 0;
    border: 1px solid $main;
    width: 295px;
    background-color: $main;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 44px;
    transition: background-color .3s, color .3s;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 20px;
    @media screen and (min-width: 1250px) {
      &:hover {
        background-color: transparent;
        color: $main;
        transition: background-color .3s, color .3s;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .contact-us__form {
    width: 100%;
  }
  .contact-us .jcf-select.jcf-unselectable {
    min-width: 100px;
  }
  .contact-us__item {
    &--name {
      width: 140px;
    }
    &--address {
      width: 100%;
      > label {
        text-align: center;
      }
    }
  }
  .contact-us__button {
    float: none;
    width: 100%;
  }
  .contact-us__title {
    font-size: 26px;
    line-height: 28px;
  }
}
@media screen and (max-width: 479px) {
  .contact-us__item {
    text-align: center;
    width: 100%;
  }
  .contact-us .jcf-select.jcf-unselectable {
    text-align: center;
  }
}
