.product {
  display: flex;
  padding-bottom: 60px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 30px;
  &__sliders {
    width: 700px;
    margin-right: 40px;
  }
  &__slider--main {
    margin-bottom: 8px;
    width: 100%;
    a {
      display: inline-block;
    }
  }
  &__slider .slick-dots {
    position: static;
    display: block;
    text-align: center;
    transform: translateY(0);
    margin-top: 25px;
    li {
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
      margin-right: 45px;
      border-color: #cdcdcd;
      &::after {
        border-bottom: 2px solid rgba(#cdcdcd, .3);
        top: 50%;
        height: 0;
        width: 45px;
        transform: translate(-115%, 0);
      }
      &.slick-active {
        background-color: $main;
        border: none;
        &::before {
          border-color: $main;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__slide {
    &--nav.slick-slide img {
      height: 93px;
    }
    &--main {
      position: relative;
      .fullsize {
        bottom: 20px;
        right: 25px;
      }
    }
  }
  &__info {
    width: calc(100% - 740px);
    flex: 0 0 auto;
  }
  &__title {
    font-size: 46px;
    line-height: 48px;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 45px;
  }
  & .catalog__description {
    font-size: 16px;
    border: none;
  }
  & .catalog__value {
    width: 51%;
  }
  &__link {
    display: block;
    text-align: center;
    margin-bottom: 25px;
  }
  &__price,
  &__special-price {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
    & span {
      margin-right: 10px;
    }
    & span + span {
      color: #000;
      margin-right: 0;
    }
  }
  &__price {
    margin-bottom: 15px;
    span {
      color: #838383;
    }
  }
  &__special-price {
    margin-bottom: 20px;
    span {
      color: $main;
    }
  }
  &__charter-price {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;
    span {
      margin-right: 8px;
    }
    span + span {
      color: $main;
    }
    span + span + span {
      font-weight: 400;
      font-size: 12px;
      color: #848484;
    }
  }
}
@media screen and (max-width: 1279px) {
  .product__sliders {
    width: 600px;
    flex: 0 0 600px;
  }
  .product__info {
    width: calc(100% - 640px);
  }
}
@media screen and (max-width: 1023px) {
  .product {
    display: block;
  }
  .product__sliders {
    width: 100%;
    margin-bottom: 50px;
    margin-right: 0;
    // flex: 0 0 100%;
  }
  .product {
    flex-direction: column;
  }
  .product__info {
    // flex: 0 0 400px;
    width: 400px;
    margin: 0 auto;
    text-align: center;
  }
  .product .catalog__value {
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  .product__slide--nav.slick-slide img {
    height: auto;
  }
}
@media screen and (max-width: 479px) {
  .product {
    border-bottom: none;
  }
  .product__slider .slick-dots li {
    margin-right: 20px;
    &::after {
      display: none;
    }
  }
  .product__info {
    width: 100%;
  }
  .product .catalog__description {
    font-size: 14px;
  }
  .product .button {
    font-size: 14px;
  }
  .product {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
}
.product-btn {
  text-align: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e8e8e8;
  a {
    display: inline-block;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    width: 264px;
    text-transform: uppercase;
    background-color: #05a0a4;
  }
}
