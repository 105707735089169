.travel-nav {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-family: 'MullerRegular';
    li {
      width: 269px;
      font-size: 15px;
      a {
        display: block;
        background-color: #000;
        text-align: center;
        color: #fff;
        text-decoration: none;
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
        transition: background-color .3s;
        @media screen and (min-width: 1250px) {
          &:hover {
            background-color: $main;
            transition: background-color .3s;
          }
        }
      }
      img {
        vertical-align: top;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 112px;
        padding: 0 45px;
      }
    }
  }
}
@media screen and (max-width: 1279px) {
  .travel-nav ul li img {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 1023px) {
  .travel-nav ul {
    flex-wrap: wrap;
    justify-content: space-around;
    > li {
      margin-bottom: 20px;
      width: 47%;
      span {
        height: 75px;
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .travel-nav ul > li {
    width: 100%;
  }
}
