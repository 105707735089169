.latest-news {
  padding: 100px 30px 0;
  &.catalog-latest-news {
    margin-bottom: 90px;
  }
  &__title {
    font-family: 'Playfair Display', serif;
    display: block;
    text-transform: uppercase;
    font-size: 26px;
    margin-bottom: 60px;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      @extend %icon-lumar;
      left: 0;
      top: -100px;
      z-index: -1;
    }
  }
  &__link {
    display: inline-block;
    color: #bdbdbd;
    text-transform: uppercase;
    text-decoration: underline;
    margin-left: 25px;
    &:hover {
      text-decoration: none;
    }
  }
}
.news-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  &__item {
    width: 253px;
    transition: box-shadow .3s;
    @media screen and (min-width: 1250px) {
      &:hover {
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
        transition: box-shadow .3s;
      }
      &:hover .news-list__date {
        background-color: $main;
        transition: background-color .3s;
      }
      &:hover svg {
        fill: $main;
        transition: fill .3s;
      }
      &:hover .news-list__title {
        color: $main;
      }
    }
  }
  &__image {
    position: relative;
    display: block;
    img {
      vertical-align: top;
    }
  }
  &__date {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    background-color: #000;
    font-size: 13px;
    line-height: 31px;
    padding: 0 25px;
    transition: background-color .3s;
  }
  &__info {
    padding: 20px 25px;
  }
  &__title {
    display: inline-block;
    font-size: 13px;
    color: #000;
    margin-bottom: 15px;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'MullerRegular';
  }
  &__text {
    font-weight: 300;
    color: #797979;
    line-height: 22px;
    margin-bottom: 20px;
  }
  &__link {
    svg {
      fill: #000;
      width: 80px;
      height: 8px;
      transition: fill .3s;
    }
  }
}
@media screen and (max-width: 1279px) {
  .news-list__image img {
    width: 100%;
    height: auto;
  }
  .news-list__item {
    width: 230px;
  }
}
@media screen and (max-width: 1023px) {
  .news-list {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .news-list__item {
    width: 235px;
  }
  .latest-news {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .news-list__item {
    width: 100%;
  }
  .latest-news__title::before {
    display: none;
  }
  .latest-news {
    padding-top: 0;
  }
}
