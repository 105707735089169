.hamb {
  display: none;
  &__lines {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
  }
  &__line {
    width: 100%;
    height: 3px;
    background-color: #fff;
  }
  &__menu {
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 350px;
    background-color: #171717;
    z-index: 9;
    transform: translateX(100%);
    opacity: 0;
    transition: transform .4s, opacity .4s;
    padding-top: 20px;
    &.active {
      transform: translateX(0);
      opacity: 1;
      transition: transform .4s, opacity .4s;
    }
  }
  &__item {
    margin-bottom: 15px;
    a {
      text-decoration: none;
      color: #fff;
      font-family: 'MullerBold';
      text-transform: uppercase;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 2px;
    }
    &.dropdown a::after {
      content: '';
      display: inline-block;
      margin: 0 0 3px 5px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 3px 0;
      border-color: #fff transparent transparent;
    }
  }
  &__close {
    position: absolute;
    left: 40px;
    top: 20px;
    svg {
      width: 20px;
      height: 30px;
      fill: #fff;
    }
  }
  &__list {
    list-style-type: none;
    color: #fff;
    text-align: left;
    margin: 50px 0 0;
  }
}
.dropdown-menu {
  display: none;
  list-style-type: none;
  margin: 0;
  padding-left: 20px;
}
@media screen and (max-width: 1023px) {
  .hamb {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .hamb__close {
    left: calc(50% + 175px);
    top: 16px;
  }
  .hamb__menu {
    width: 100vw;
    min-height: 100vh;
  }
  .hamb__list {
    text-align: center;
    padding: 0;
  }
  .dropdown-menu {
    padding: 0;
  }
  .hamb__item {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 479px) {
  .hamb__close {
    left: calc(50% + 125px);
  }
}
