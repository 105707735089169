.building-process {
  margin-bottom: 100px;
  @media screen and (max-width: 1279px) {
    margin-bottom: 40px;
  }
  .title-iteration2 {
    margin-bottom: 40px;
    @media screen and (max-width: 1279px) {
      margin-bottom: 20px;
    }
  }
  > .text {
    margin-bottom: 65px;
    color: #262626;
    @media screen and (max-width: 1279px) {
      margin-bottom: 20px;
    }
  }
  .title {
    position: relative;
    margin-bottom: 55px;
    @media screen and (max-width: 1023px) {
      margin-bottom: 20px;
    }
    &::before {
      @extend %pseudo;
      position: absolute;
      height: 1px;
      width: 100%;
      left: 0;
      bottom: -20px;
      background-color: #e8e8e8;
      @media screen and (max-width: 1023px) {
        bottom: -10px;
      }
    }
  }
}
.building-process-img-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 9999px;
  margin: 0 -9999px 80px;
  background: #f7f7f7;
  @media screen and (max-width: 1279px) {
    margin: 0 -9999px 30px;
  }
  .title {
    @media screen and (max-width: 1279px) {
      padding: 15px 0 0;
    }
  }
  .img-box {
    flex: 0 0 auto;
    margin: 0 35px 0 0;
    @media screen and (max-width: 1279px) {
      width: 400px;
      margin: 0 15px 0 0;
    }
    @media screen and (max-width: 1023px) {
      display: none;
    }
    img {
      display: inline-block;
      vertical-align: top;
      max-width: 100%;
      height: auto;
    }
  }
  .text {
    margin-bottom: 0;
  }
}
.building-process-item {
  margin-bottom: 60px;
  @media screen and (max-width: 1279px) {
    margin-bottom: 35px;
  }
  .text {
    margin-bottom: 0;
  }
  &:last-child {margin-bottom: 0;}
}
