.fixed-contact {
  display: block;
  position: fixed;
  top: 194px;
  right: 0;
  &__close {
    display: none;
  }
  &__small {
    cursor: pointer;
    position: relative;
    font-family: 'Open Sans', sans-serif;
    width: 244px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    background-color: $main;
    &::after {
      position: absolute;
      content: '';
      right: 16px;
      top: 50%;
      margin-top: -3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 3px 0;
      border-color: #fff transparent transparent;
      z-index: 2;
    }
    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 40px;
      height: 100%;
      background-color: $main;
      z-index: 1;
      right: 0;
      top: 0;
    }
    &.active {
      background-color: #f2f2f2;
      color: transparent;
      &::after {
        border-width: 0 3px 6px;
        border-color: transparent transparent #fff;
      }
    }
  }
  &__drop {
    display: none;
    width: 244px;
    padding: 22px;
    background-color: #f2f2f2;
    form {
      width: 100%;
    }
    input {
      line-height: 32px;
      height: 32px;
    }
    input,
    textarea {
      width: 100%;
      border: 1px solid #e8e8e8;
      border-radius: 0;
      box-shadow: none !important;
      background-color: #fff;
      margin-bottom: 10px;
      padding: 0 10px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      font-size: 12px;
      color: #000;
    }
    textarea {
      resize: none;
      margin-bottom: 20px;
      height: 80px;
      padding: 10px;
    }
    button {
      width: 100%;
      background-color: $main;
      border-radius: 0;
      border: 1px solid $main;
      color: #fff;
      line-height: 38px;
      cursor: pointer;
      @media screen and (min-width: 1250px) {
        &:hover {
          background-color: transparent;
          color: $main;
        }
      }
    }
  }
  &__img {
    width: 106px;
    height: 106px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;
    & img {
      vertical-align: middle;
    }
  }
  &__title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1650px) {
  .fixed-contact {
    bottom: 0;
    top: auto;
  }
}
@media screen and (max-width: 767px) {
  .fixed-contact__drop {
    padding-top: 110px;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .fixed-contact__close {
    display: flex;
  }
  .fixed-contact__small::before,
  .fixed-contact__small::after {
    z-index: 0;
  }
}
@media screen and (max-width: 479px) {
  .fixed-contact__small {
    width: 100vw;
  }
  .fixed-contact__img {
    display: none;
  }
}
