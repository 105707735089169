.header {
  background-color: #171717;
  padding: 12px 0 0;
  position: relative;
  &.fixed {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100vw;
  }
  &__content {
    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
  &__nav {
    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
  &__top {
    text-align: right;
    margin-bottom: 50px;
    padding-right: 30px;
    &-link {
      color: #9e9e9e;
      display: inline-block;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 300;
      margin-left: 40px;
      text-decoration: none;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        color: $main;
      }
    }
  }
  &__lang {
    display: inline-block;
    margin-left: 40px;
    color: #9e9e9e;
    position: relative;
    font-size: 10px;
    min-width: 58px;
    &::before {
      background-color: transparent;
      left: -20px;
      right: -20px;
      bottom: 0;
      top: -15px;
      position: absolute;
      content: '';
    }
    &-active {
      position: relative;
      cursor: pointer;
      padding-right: 15px;
      &::after {
        position: absolute;
        content: '';
        right: 0;
        top: 4px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 3px 0 3px;
        border-color: #9e9e9e transparent transparent;
      }
    }
    &.active {
      &::before {
        background-color: #232323;
      }
    }
  }
  &__lang-list {
    margin: 0;
    padding: 6px 0 10px 20px;
    list-style: none;
    position: absolute;
    background-color: #171717;
    z-index: 1;
    left: -20px;
    right: -20px;
    text-align: left;
    display: none;
    &.active {
      display: block;
      background-color: #232323;
      &::after {
        border-width: 0 3px 6px;
        border-color: transparent transparent #9e9e9e;
      }
    }
    li {
      margin-top: 8px;
      text-transform: uppercase;
      font-size: 10px;
      a {
        color: #fff;
        text-decoration: none;
        padding-left: 8px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          left: 0;
          top: 5px;
          border-left: 1px solid transparent;
          height: 6px;
        }
        &:hover {
          color: $main;
          &::after {
            border-color: $main;
          }
        }
      }
    }
  }
}
.logo {
  float: left;
  margin: 0 30px 22px 0;
  width: 196px;
  height: 130px;
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  background: url('../images/logo.png') no-repeat;
  background-size: 100%;
  a {
    display: block;
    height: 100%;
  }
  .header & {
    margin-bottom: 0;
    margin-right: 45px;
  }
}
.nav {
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: 'MullerRegular';
    text-transform: uppercase;
    display: flex;
    > li {
      font-size: 15px;
      padding-bottom: 42px;
      line-height: 18px;
      width: 20%;
      &:first-child a {
        border-left: none;
      }
      &:hover {
        > .lumar-dropdown {
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
          transition: opacity .3s, transform .3s;
        }
      }
      > a {
        color: #f9f9f9;
        text-decoration: none;
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        text-align: center;
        padding: 0 10px;
        height: 38px;
        border-left: 1px solid #fff;
        &::after {
          position: absolute;
          content: '';
          bottom: -10px;
          border-bottom: 1px solid $main;
          left: 50%;
          width: 50px;
          transform: translateX(-50%);
          display: none;
        }
        &:hover {
          color: $main;
        }
        &:hover::after {
          display: block;
        }
      }
    }
  }
}
.lumar-dropdown {
  position: absolute;
  width: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  top: 100%;
  z-index: 1;
  padding: 40px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-30px);
  transition: opacity .3s, transform .3s;
  &__title {
    text-transform: uppercase;
    display: block;
    color: $main;
    position: relative;
    text-decoration: none;
    padding-bottom: 13px;
    font-family: 'MullerBold';
    margin-bottom: 20px;
    &::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      border-bottom: 1px solid $main;
      width: 50px;
    }
  }
  ul {
    margin: 0;
    padding: 0 10px 0 0;
    list-style: none;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 16px;
    width: 300px;
    li {
      margin-bottom: 15px;
      letter-spacing: 1px;
      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          color: $main;
        }
      }
    }
  }
  &__menu-area {
    display: flex;
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__links {
    display: flex;
    width: 550px;
    justify-content: space-between;
  }
  &__holder {
    width: 225px;

  }
  &__box {
    &-image {
      margin-bottom: 15px;
      display: block;
      img {
        vertical-align: top;
      }
    }
    &-title {
      color: #fff;
      display: block;
      text-decoration: none;
      font-size: 13px;
      text-transform: uppercase;
      &:hover {
        color: $main;
      }
    }
  }
}
.mobile-logo {
  display: none;
  background: url(../images/mobile-logo.png) no-repeat;
  background-size: 100% 100%;
  width: 81px;
  height: 31px;
  text-indent: -9999px;
  overflow: hidden;
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.mobile-menu {
  width: auto;
}
@media screen and (max-width: 1279px) {
  .lumar-dropdown {
    background-color: #171717;
  }
  .nav > ul > li > a {
    padding: 0 4px;
  }
}
@media screen and (max-width: 1023px) {
  .nav,
  .logo {
    display: none;
  }
  .mobile-logo {
    display: inline-block;
  }
  .header__top {
    text-align: left;
    padding: 0;
    margin: 0;
  }
  .header__nav {
    width: auto;
  }
  .header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &::after {
      display: none;
    }
  }
  .header {
    padding: 15px 0;
  }
  .header__lang,
  .header__top-link {
    margin-left: 15px;
  }
}
@media screen and (max-width: 767px) {
  .header__top-link {
    display: none;
  }
  .header__lang {
    margin-left: 0;
    margin-right: 15px;
}
}
@media screen and (max-width: 479px) {
  .header__lang {
    margin: 0;
  }
  .header__lang::before {
    display: none;
  }
  .header__lang-active {
    font-size: 14px;
  }
}
