.title-iteration2 {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  font-size: 40px;
  margin: 0 0 25px;
  @include response(1024px) {
    font-size: 28px;
  }
}
div.content-info-iteration2 {
  .content-info__item {
    margin-bottom: 40px;
    @include response(1279px) {
      margin-bottom: 20px;
    }
  }
  .content-info__title {
    margin-bottom: 50px;
    @include response(1279px) {
      margin-bottom: 20px;
    }
  }
}
.text.text-iteration2 {
  margin-bottom: 60px;
  @include response(1279px) {
    margin-bottom: 20px;
  }
}
.contact-us-form {
  padding: 30px 17px;
  position: relative;
  background: url('../images/decor-contact.png') center/cover no-repeat;
  @include response(1279px) {
    margin-left: 0;
  }
}
.contact-us-holder {
  padding: 40px 0 0;
  margin: 0 0 95px;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-top: 1px solid #000;
  @include response(1279px) {
    flex-wrap: wrap;
    padding: 10px 0 0;
  }
  .text.text-iteration2 {
    margin-bottom: 40px;
    @include response(1279px) {
      margin-bottom: 20px;
    }
  }
  .crew-popup__note {
    margin-bottom: 40px;
    @include response(1279px) {
      margin-bottom: 20px;
    }
  }
  .crew-popup__item {
    margin-bottom: 55px;
    @include response(1279px) {
      margin-bottom: 15px;
    }
  }
  input[type="checkbox"] {
    vertical-align: top;

  }
  input[type="checkbox"]::before {
    background: #fff;
  }
  .crew-popup__checkbox {
    font-size: 16px;
    font-weight: 300;
    color: #8b8b8b;
  }
  .crew-popup__checkbox label {
    max-width: 162px;
    cursor: pointer;
  }
  .crew-popup__item .crew-popup__checkbox label {
    color: #8b8b8b;
    font-size: 16px;
    text-transform: inherit;
  }
  .crew-popup__item .crew-popup__checkbox input[type="checkbox"] {
    vertical-align: middle;
  }
  .crew-popup__specify {
    margin-bottom: 40px;
    @include response(1279px) {
      margin-bottom: 20px;
    }
  }
  .button {
    padding: 0 50px;
  }
}
.crew-popup__checkbox label {
  cursor: pointer;
}
.button {
  cursor: pointer;
}
.our-offices-list {
  margin-left: 16px;
  flex: 0 0 auto;
  @include response(1279px) {
    margin-left: 0;
    display: flex;
    padding-top: 20px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  > .title {
    text-transform: uppercase;
    font-size: 21px;
    color: #333;
    margin-bottom: 10px;
    @include response(1279px) {
      width: 100%;
      flex: 0 0 auto;
    }
  }
  .item {
    margin-bottom: 18px;
    background: #ebf1f6;
    @include response(1279px) {
      width: 48%;
    }
    @include response(767px) {
      width: 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .img-box {
      height: 206px;
      @include response(1279px) {
        height: auto;
      }
      img {
        max-width: 100%;
        height: auto;
        display: inline-block;
        vertical-align: top;
        @include response(1279px) {
          width: 100%;
        }
      }
    }
    .info-box {
      padding: 25px 20px 35px;
      .name {
        font-size: 21px;
        font-weight: 400;
        font-family: 'Playfair Display', serif;
        color: #333;
        text-transform: uppercase;
        margin: 0 0 22px;
      }
    }
    .contacts {
      padding: 0 0 20px;
      border-bottom: 1px solid #fff;
      margin: 0 0 20px;
      a {
        position: relative;
        text-decoration: none;
        padding: 0 0 0 25px;
        color: #000;
        color: #333;
        display: block;
        font-size: 13px;
        em {
          position: absolute;
          left: 0;
          font-style: normal;
          text-transform: uppercase;
        }
      }
    }
    .schedule {
      .title {
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        margin: 0;
        @include response(1279px) {
          text-align: left;
        }
      }
      .schedule-value {
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;
        color: #797979;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 400;
      }
    }
  }
}
.contact-us-holder.contact-us-holder-top {
  border-top: 0;
  padding: 0;
}
