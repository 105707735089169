.crew {
  .text {
    margin-bottom: 80px;
  }
  &__items-holder {
    position: relative;
    z-index: 1;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 55px;
    @include response(1279px) {
      justify-content: space-around;
    }
  }
  &__item {
    width: 275px;
    box-shadow: 0 0 26px rgba(7, 11, 34, 0.05);
    margin-bottom: 13px;
    @include response(1279px) {
      margin-bottom: 40px;
    }
  }
  &__img {
    display: block;
    margin-bottom: 30px;
    img {
      vertical-align: middle;
    }
  }
  &__description {
    padding: 0 20px;
  }
  .catalog__description {
    align-items: center;
    border-bottom: 1px dashed #f7f7f8;
  }
  .catalog__value {
    width: 105px;
    text-align: right;
  }
  .catalog__title {
    text-transform: uppercase;
    padding-left: 20px;
    margin-bottom: 20px;
    font-weight: 700;
  }
  &__description {
    margin-bottom: 10px;
  }
  &__hire {
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 56px;
    color: $main;
    text-transform: uppercase;
    text-align: center;
    border-top: 1px dashed $main;
    transition: background-color .3s, color .3s;
    @include desktop-hover() {
      color: #fff;
      background-color: $main;
      transition: background-color .3s, color .3s;
    }
  }
  &__more {
    display: block;
    width: 310px;
    margin: 0 auto 60px;
    line-height: 44px;
    text-align: center;
  }
}
.crew-decor.crew-decor-2 {
  @extend %icon-crew-decor;
  margin: 0 auto 90px;
}
.crew-decor {
  @extend %icon-logo-lion;
  margin: 0 auto 30px;
  @include response(767px) {
    display: none;
  }
}
.crew-latest-news.latest-news {
  padding-top: 0;
  .latest-news__title::before {
    display: none;
  }
}
