.product-inside {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 90px;
  &.product-about {
    padding-bottom: 80px;
    margin-bottom: 70px;
  }
  .subtitle {
    text-transform: none;
    font-size: 36px;
    line-height: 38px;
    margin-bottom: 35px;
  }
  &__item {
    width: 50%;
    margin-bottom: 60px;
    &:nth-child(2n+1) {
      border-right: 1px solid #e8e8e8;
    }
    &:nth-child(2n) {
      padding-left: 60px;
    }
  }
  &__description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .catalog__description {
    border-bottom: none;
    width: 50%;
  }
  .catalog__value {
    width: 100px;
  }
  &__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #797979;
    font-size: 15px;
    line-height: 22px;
  }
}
@media screen and (max-width: 1023px) {
  .product-inside .catalog__value {
    width: auto;
  }
  .product-inside__item {
    width: 100%;
  }
  .product-inside__item:nth-child(2n) {
    padding: 0;
  }
  .product-inside__item:nth-child(2n+1) {
    border-right: none;
  }
}
@media screen and (max-width: 767px) {
  .product-inside__description {
    flex-direction: column;
  }
  .product-inside .catalog__description {
    width: 100%;
    text-align: center;
  }
  .product-inside__item {
    margin-bottom: 20px;
  }
  .product-inside .subtitle {
    margin-bottom: 20px;
  }
  .product-inside {
    margin-bottom: 30px;
  }
  .product-inside__text {
    max-height: 300px;
    overflow-y: auto;
  }
  .product-inside .catalog__description {
    border-bottom: 1px solid #ccc;
  }
}
