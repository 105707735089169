@font-face {
  font-family: 'MullerRegular';
  src:  url('../fonts/MullerRegular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MullerRegular.otf')  format('opentype'),
        url('../fonts/MullerRegular.woff') format('woff'),
        url('../fonts/MullerRegular.ttf')  format('truetype'),
        url('../fonts/MullerRegular.svg#MullerRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MullerBold';
  src:  url('../fonts/MullerBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MullerBold.otf')  format('opentype'),
        url('../fonts/MullerBold.woff') format('woff'),
        url('../fonts/MullerBold.ttf')  format('truetype'),
        url('../fonts/MullerBold.svg#MullerBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'mullerThin';
    src: url('../fonts/mullerthin-webfont.eot');
    src: url('../fonts/mullerthin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/mullerthin-webfont.woff2') format('woff2'),
         url('../fonts/mullerthin-webfont.woff') format('woff'),
         url('../fonts/mullerthin-webfont.ttf') format('truetype'),
         url('../fonts/mullerthin-webfont.svg#mullerthin') format('svg');
    font-weight: normal;
    font-style: normal;
}
body {
  height: 100%;
  margin: 0;
  // font-family: 'MullerRegular';
  // font-family: 'Playfair Display', serif;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  color: $black;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
* {
  box-sizing: border-box;
  outline: none;
  &::after,
  &::before {
    box-sizing: border-box;
  }
}
form input,
form textarea {
  box-shadow: none !important;
  border-radius: 0;
  transition: border-color .3s;
  &.error {
    border-color: #d52323;
    transition: border-color .3s;
  }
}
p.error {
  color: #d52323;
  margin: -5px 0 10px;
  font-size: 11px;
}
.wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.align {
  max-width: 1140px;
  margin: 0 auto;
  @media screen and (max-width: 1279px) {
    width: 1000px;
  }
  @media screen and (max-width: 1023px) {
    width: 700px;
  }
  @media screen and (max-width: 767px) {
    width: 400px;
  }
  @media screen and (max-width: 479px) {
    width: 300px;
  }
}
.button {
  border: 1px solid $main;
  background-color: $main;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 44px;
  transition: background-color .3s, color .3s;
  text-transform: uppercase;
  @media screen and (min-width: 1250px) {
    &:hover {
      background-color: transparent;
      color: $main;
      transition: background-color .3s, color .3s;
    }
  }
}
.fullsize {
  cursor: pointer;
  pointer-events: none;
  position: absolute;
  width: 46px;
  height: 46px;
  background-color: #000;
  border-radius: 50%;
  text-align: center;
  @extend %before-vam;
  svg {
    display: inline-block;
    vertical-align: middle;
    width: 19px;
    height: 19px;
    fill: #fff;
  }
}
.fancybox-controls {
  position: fixed;
}
.fancybox-infobar .fancybox-button {
  position: fixed;
  &::after {
    width: 10px;
    height: 10px;
    border-color: $main;
    border-top: 2px solid $main;
    border-right: 2px solid $main;
  }
  &--left {
    left: 0;
    top: 50%;
    margin-top: -22px;
    &::after {
      left: 18px;
    }
  }
  &--right {
    right: 0;
    top: 50%;
    margin-top: -22px;
    &::after {
      right: 18px;
    }
  }
}
.fancybox-infobar__body {
  position: fixed;
  font-size: 20px;
  top: 91%;
  left: 50%;
  margin-left: -35px;
  background-color: transparent;
  color: $main;
}
