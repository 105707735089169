.sidebar {
  width: 290px;
  position: absolute;
  margin-bottom: 70px;
  &__title {
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    margin-bottom: 40px;
  }
  &__item {
    margin-bottom: 30px;
  }
  &__label {
    color: #8b8b8b;
    font-family: 'Open sans';
    text-transform: uppercase;
    font-size: 13px;
    line-height: 15px;
    font-weight: 700;
    margin-bottom: 20px;
    > label {
      margin-right: 20px;
    }
    > input[type="radio"] {
      cursor: pointer;
      opacity: 0;
      width: 15px;
      height: 11px;
      + span {
        margin-left: -14px;
        font-weight: 400;
      }
    }
    > input[type="radio"]:checked + span {
      color: $main;
    }
  }
  .jcf-select.jcf-unselectable {
    border: 1px solid #e6e6e6;
    padding: 0 20px;
    min-width: 100%;
    width: 100%;
    height: auto;
  }
  .jcf-select span.jcf-select-text {
    line-height: 44px;
    font-size: 13px;
    font-family: 'Open sans';
  }
  &__button {
    width: 100%;
    background-color: transparent;
    border: 1px solid $main;
    line-height: 41px;
    text-align: center;
    color: $main;
    text-transform: uppercase;
    font-family: 'Open sans';
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    transition: background-color .3s, color .3s;
    @media screen and (min-width: 1250px) {
      &:hover {
        background-color: $main;
        color: #fff;
        transition: background-color .3s, color .3s;
      }
    }
  }
}
@media screen and (max-width: 1439px) {
  .sidebar {
    width: 230px;
  }
}
@media screen and (max-width: 1024px) {
  .sidebar {
    position: static;
    width: 100%;
    margin-bottom: 50px;
  }
  .sidebar__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    align-items: center;
  }
  .sidebar__item {
    height: auto;
    margin: 0 0 20px;
    width: 48%;
  }
  .jcf-select .jcf-select-opener::after {
    right: 6px;
  }
  .sidebar__button {
    display: block;
    width: 200px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .sidebar__title {
    text-align: center;
  }
  .sidebar__item {
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .sidebar__item {
    text-align: center;
  }
  .sidebar__label {
    font-size: 11px;
    line-height: 13px;
    > label {
      margin-right: 5px;
    }
  }
}
.sidebar.is_stuck + div {
  pointer-events: none;
}
