.filter {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 45px;
  .jcf-select.jcf-unselectable {
    min-width: auto;
    border: 1px solid #e8e8e8;
    display: inline-block;
    vertical-align: middle;
    padding: 0 40px 0 30px;
  }
  .jcf-select span.jcf-select-text {
    font-family: 'Open Sans', sans-serif;
    line-height: 44px;
    font-weight: 300;
    text-transform: none;
  }
  label {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 1px;
    color: #bdbdbd;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }
  &__button {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 44px;
    font-weight: 300;
    color: #282828;
    border: 1px solid #282828;
    background-color: transparent;
    padding: 0 10px;
    cursor: pointer;
  }
  &__item {
    font-size: 0;
  }
}
@media screen and (max-width: 1023px) {
  .filter {
    flex-direction: column;
    align-items: center;
    .jcf-select.jcf-unselectable {
      padding: 0 20px;
      width: 300px;
    }
    label {
      display: block;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .filter__item {
    margin-bottom: 20px;
  }
  .jcf-select-filter__currency.jcf-select.jcf-unselectable {
    width: auto;
  }
}
@media screen and (max-width: 479px) {
  .filter .jcf-select.jcf-unselectable {
    width: 280px;
  }
}
