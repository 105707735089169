.advantage {
  background: url('../images/why-we.jpg');
  background-size: cover;
  height: 605px;
  &.sales-advantage {
    background: url('../images/sales-why-we.jpg');
  }
  &.build-advantage {
    background: url('../images/build-why-we.jpg');
  }
  & .align {
    height: 100%;
    @extend %clearfix;
  }
  &__info {
    float: right;
    width: 620px;
    height: 100%;
    color: #fff;
    background-color: rgba(#101413, .8);
    padding: 0 90px 0 50px;
    display: flex;
    align-items: center;
  }
  &__title {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    font-size: 46px;
    line-height: 48px;
    margin-bottom: 45px;
  }
  &__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 60px;
  }
  &__button {
    display: inline-block;
    padding: 0 70px;
    background-color: #fff;
    color: #010204;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 46px;
    transition: background-color .3s, color .3s;
    text-transform: uppercase;
    @media screen and (min-width: 1250px) {
      &:hover {
        background-color: $main;
        color: #fff;
        transition: background-color .3s, color .3s;
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .advantage .align {
    width: 100%;
  }
  .advantage__info {
    float: none;
    width: 100%;
    padding: 0 50px;
  }
}
@media screen and (max-width: 767px) {
  .advantage__text {
    max-height: 215px;
    overflow-y: auto;
  }
  .advantage__title {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 479px) {
  .advantage__info {
    padding: 0 20px;
  }
}
