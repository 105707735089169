.team {
  .lang1,
  .lang2,
  .lang3,
  .lang4,
  .lang5,
  .lang6,
  .mail-icon,
  .phone-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  .mail-icon {
    @extend %icon-mail;
    margin-right: 10px;
  }
  .phone-icon {
    margin-right: 10px;
    @extend %icon-phone;
  }
  .lang1 {
    @extend %icon-lang1;
  }
  .lang2 {
    @extend %icon-lang2;
  }
  .lang3 {
    @extend %icon-lang3;
  }
  .lang4 {
    @extend %icon-lang4;
  }
  .lang5 {
    @extend %icon-lang5;
  }
  .lang6 {
    @extend %icon-lang6;
  }
  &__item {
    display: flex;
    padding-bottom: 55px;
    margin-bottom: 55px;
    border-bottom: 1px solid #e8e8e8;
    &:last-child {
      border: none;
    }
  }
  &__img {
    margin-right: 30px;
    img {
      vertical-align: middle;
    }
  }
  &__name {
    font-family: 'Playfair Display', serif;
    letter-spacing: 1px;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
  }
  &__position {
    font-family: 'Open sans', sans-serif;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  &__phone {
    font-family: 'MullerRegular';
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 1px;
    margin-right: 30px;
  }
  &__mail {
    border: 1px solid $main;
    color: $main;
    line-height: 32px;
    font-size: 12px;
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    transition: border-color .3s;
    padding: 0 20px;
    text-align: center;
    @media screen and (min-width: 1250px) {
      &:hover {
        border-color: transparent;
        transition: border-color .3s;
      }
    }
  }
  &__lang {
    margin-right: 40px;
  }
  &__lang,
  &__phone,
  &__mail {
    display: inline-block;
    vertical-align: middle;
  }
}
@media screen and (max-width: 1279px) {
  .team__item {
    align-items: center;
  }
}
@media screen and (max-width: 1023px) {
  .team__item {
    flex-direction: column;
  }
  .team__img {
    margin: 0 0 20px;
  }
  .team {
    text-align: center;
  }
  .team__item {
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .team__img  img {
    width: 100%;
    height: auto;
  }
  .team__lang,
  .team__phone,
  .team__mail {
    display: block;
    margin: 0 auto 20px;
  }
  .team__mail {
    width: 150px;
    padding: 0;
  }
}
