.yacht-registration-holder {
  margin-bottom: 90px;
    @media screen and (max-width: 767px) {
      margin-bottom: 60px;
    }
  .text.black-text {
    margin: 0 0 30px;
    color: #262626;
    max-width: 670px;
  }
  .text {
    margin-bottom: 30px;
  }
  .button {
    padding: 12px 10px;
    min-width: 453px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    line-height: inherit;
    margin: 55px 0 0;
    @media screen and (max-width: 767px) {
      min-width: 100%;
      margin: 10px 0 0;
      font-size: 12px;
    }
  }
}
.list-registration {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  .item-registration {
    border-bottom: 1px solid #e5e5e5;
    &:last-child {
      border-bottom: 0;
    }
  }
}
.item-registration {
  @extend %clearfix;
  padding: 32px 0;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  .img-box {
    float: left;
    margin: 0 22px 0 0;
    @media screen and (max-width: 767px) {
      float: none;
      margin: 0 0 10px;
    }
  }
  .info-holder {
    overflow: hidden;
  }
  .text {
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      text-align: left;
    }
  }
}
.title-registration {
  font-size: 21px;
  font-weight: 400;
  color: #221238;
  font-family: 'Playfair Display', serif;
  margin-bottom: 22px;
}
.date-registration {
  font-size: 10px;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  color: #05a0a4;
  margin-bottom: 20px;
}
