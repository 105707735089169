.event-section {
  padding: 85px 0;
  &__icon {
    @extend %icon-logo-lion;
    margin: 0 auto 30px;
  }
  &__title {
    font-family: 'Playfair Display', serif;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 26px;
    margin-bottom: 40px;
  }
}
.event-list {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  &__item {
    max-width: 348px;
    width: 100%;
    transition: box-shadow .3s;
    @media screen and (min-width: 1250px) {
      &:hover {
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
        transition: box-shadow .3s;
      }
      &:hover svg {
        fill: $main;
        transition: fill .3s;
      }
    }
  }
  &__image {
    display: block;
    img {
      vertical-align: top;
    }
  }
  &__info {
    padding: 20px 30px;
  }
  &__title {
    display: inline-block;
    font-size: 16px;
    color: #000;
    margin-bottom: 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'MullerRegular';
    &:hover {
      color: $main;
    }
  }
  &__text {
    font-weight: 300;
    color: #797979;
    line-height: 22px;
    margin-bottom: 20px;
  }
  &__link {
    svg {
      fill: #000;
      width: 80px;
      height: 8px;
      transition: fill .3s;
    }
  }
}
@media screen and (max-width: 1279px) {
  .event-list {
    text-align: center;
  }
  .event-list__image img {
    width: 100%;
    height: auto;
  }
  .event-list__item {
    max-width: 300px;
  }
}
@media screen and (max-width: 1023px) {
  .event-list {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
