.footer {
  &__top {
    background-color: #171717;
    padding: 80px 0 50px;
    &-wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: column;
      height: 300px;
      .col {
        max-width: 200px;
      }
    }
  }
  &__bottom {
    background-color: #fff;
    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
    }
  }
  .logo {
    float: none;
    margin: 0 0 35px;
  }
  &__copy {
    color: #9a9a9a;
  }
}
.footer-nav {
  &__link {
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    font-family: 'Playfair Display', serif;
    display: inline-block;
    margin-bottom: 25px;
    font-size: 15px;
    &:hover {
      text-decoration: underline;
    }
  }
  &__list {
    margin: 0 0 25px;
    padding: 0;
    list-style: none;
    li {
      margin-top: 8px;
      font-size: 14px;
      &:first-child {
        margin-top: 0;
      }
      a {
        color: #a8a8a8;
        text-decoration: none;
        &:hover {
          color: $main;
        }
      }
    }
  }
}
.socials {
  &__title {
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 20px;
    font-family: 'Playfair Display', serif;
  }
}
.socials-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  &__item {
    float: left;
    width: 20px;
    height: 20px;
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      .socials-list__ico {
        fill: $main;
      }
    }
  }
  &__ico {
    fill: #fff;
    &--facebook {
      width: 11px;
      height: 19px;
    }
    &--twitter {
      width: 22px;
      height: 19px;
    }
    &--instagram {
      width: 20px;
      height: 20px;
    }
    &--google {
      width: 20px;
      height: 18px;
    }
  }
}
.devs {
  display: block;
  text-decoration: none;
  color: #9a9a9a;
  &__name {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 12px;
  }
  &__ico {
    fill: #9a9a9a;
    width: 68px;
    height: 32px;
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    .devs__ico {
      fill: #000;
      color: #ff0;
    }
  }
}
@media screen and (max-width: 1023px) {
  .footer__top-wrap {
    flex-direction: row;
    height: auto;
  }
  .col {
    max-width: 100%;
  }
  .footer-nav,
  .col {
    width: 200px;
    text-align: center;
  }
  .socials-list {
    display: inline-block;
  }
}
@media screen and (max-width: 767px) {
  .footer__top {
    padding: 40px 0;
  }
  .footer__top-wrap {
    max-height: 100%;
    flex-wrap: wrap;
  }
  .footer__top-wrap .col {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .footer-nav {
    width: 100%;
  }
}
