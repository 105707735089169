.mob-wrapper {
  position: relative;
  background-color: #333;
  min-height: calc(100vh - 55px);
  padding-bottom: 55px;
}
.mob-carousel .slick-slide img,
.mob-carousel .slick-slide,
.mob-carousel {
  height: auto;
}
.mob-header {
  text-align: center;
  padding: 5px 0;
}
.mob-logo {
  background: url('../images/mobile-logo.png') no-repeat;
  background-size: 100% 100%;
  width: 55px;
  height: 21px;
  display: inline-block;
  text-indent: -9999px;
  overflow: hidden;
  a {
    display: block;
    height: 100%;
  }
}
.mob-nav {
  margin-bottom: 20px;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    position: relative;
    border-bottom: 1px solid rgba(#9b9b9b, .3);
    line-height: 40px;
    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 10px;
      width: 2px;
      background-color: $main;
      top: 50%;
      right: 15px;
    }
    &::after {
      transform: rotate(-45deg);
      margin-top: -6px;
    }
    &::before {
      transform: rotate(45deg);
    }
  }
  a {
    color: #fff;
    display: block;
    height: 100%;
    padding-left: 20px;
    text-transform: uppercase;
    font-size: 14px;
  }
}
.desktop {
  color: $main;
  font-size: 14px;
  padding-left: 20px;
}
.mob-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  .devs__name {
    display: block;
    margin: 0 0 4px;
    font-size: 10px;
  }
  .devs__ico {
    width: 45px;
    height: 20px;
  }
  .devs {
    text-align: center;
  }
  .footer__copy {
    font-size: 10px;
  }
}
