.main-carousel {
  &__item {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
.slick-dots {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  li {
    cursor: pointer;
    width: 15px;
    height: 15px;
    border: 2px solid $white;
    border-radius: 50%;
    background: none;
    text-indent: -9999px;
    position: relative;
    margin-top: 45px;
    &::before {
      content: '';
      position: absolute;
      left: -7px;
      top: -7px;
      right: -7px;
      bottom: -7px;
      border: 2px solid #00f9ff;
      border-radius: 50%;
      border-color: transparent;
    }
    &::after {
      position: absolute;
      content: '';
      border-left: 2px solid rgba(255, 255, 255, 0.3);
      top: 0;
      height: 45px;
      left: 50%;
      transform: translate(-50%, -105%);
    }
    &:first-child {
      margin-top: 0;
      &::after {
        display: none;
      }
    }
    &.slick-active {
      width: 10px;
      height: 10px;
      background-color: #00f9ff;
      border-color: transparent;
      &::before {
        border-color: #00f9ff;
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .main-carousel .slick-dots {
    right: 20px;
  }
}
@media screen and (max-width: 1100px) {
  .main-carousel .slick-dots {
    right: 5px;
  }
}
@media screen and (max-width: 1023px) {
  .main-carousel {
    height: 400px;
  }
  .main-carousel .slick-slide,
  .main-carousel .slick-slide img {
    height: 400px;
  }
}
